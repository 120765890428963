import { Outlet } from 'react-router-dom'

import NavBar from '../components/NavBar'

export default () => {
  return (
    <div>
      <NavBar />
      <main className="ttui-container">
        <Outlet />
      </main>
    </div>
  )
}
