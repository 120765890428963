import clsx from 'clsx'
import { Outlet } from 'react-router-dom'

import NavBar from '../components/NavBar'

import * as styles from './PageLayout.module.styl'

export default () => {
  return (
    <div>
      <NavBar />
      <main className={clsx('ttui-container', styles.text)}>
        <Outlet />
      </main>
    </div>
  )
}
