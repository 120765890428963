import { Link } from 'react-router-dom'

import '@tti/design/ui/components/ttui-header'

export default () => {
  return (
    <ttui-header root-url="/">
      <div slot="middle">
        <ttui-nav>
          <nav-item>
            <Link slot="nav_item__link" to="/">
              Home
            </Link>
          </nav-item>
          <nav-item>
            <Link slot="nav_item__link" to="/about">
              About this project
            </Link>
          </nav-item>
        </ttui-nav>
      </div>
    </ttui-header>
  )
}
