import '@tti/design/ui/main.css'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import AppLayout from './containers/AppLayout'
import IndexView from './containers/IndexView'
import PageLayout from './containers/PageLayout'

import AboutPage from './pages/about.mdx'

import store from './store'

const container = document.getElementById('app')
const root = createRoot(container)
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AppLayout />}>
          <Route index element={<IndexView />} />
        </Route>
        <Route element={<PageLayout />}>
          <Route path="/about" element={<AboutPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </Provider>
)
