import { useGetServerInfoQuery } from '~frontend/services/serverInfo'

import '@tti/design/ui/components/heading'

export default () => {
  const { data, error, isLoading } = useGetServerInfoQuery()

  return (
    <>
      <ttui-heading
        heading="fab-friday-template"
        subtitle="A Fab-Friday project of The Things Industries"
      ></ttui-heading>

      <h1>Server Info</h1>
      <pre>
        <code>
          {isLoading
            ? 'loading...'
            : Boolean(error)
            ? error.data
            : JSON.stringify(data, null, '  ')}
        </code>
      </pre>
    </>
  )
}
