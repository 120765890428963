import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import { createApi } from '@reduxjs/toolkit/query/react'
import type { ServerInfoResponse } from '~backend'

import { API_BASE_URL } from './client'

export const serverInfoApi = createApi({
  reducerPath: 'serverInfoApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ['ServerInfo'],
  endpoints: (builder) => ({
    getServerInfo: builder.query<ServerInfoResponse, void>({
      query: () => `server-info`,
      providesTags: ['ServerInfo'],
    }),
  }),
})

export const { useGetServerInfoQuery } = serverInfoApi
