import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

import { serverInfoApi } from './services/serverInfo'

const store = configureStore({
  reducer: {
    [serverInfoApi.reducerPath]: serverInfoApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(serverInfoApi.middleware),
})

setupListeners(store.dispatch)

export default store
